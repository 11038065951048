import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { TourProvider } from '@reactour/tour';
import TourContent from 'components/common/Tour/TourContent';

import "@fontsource/noto-sans"; 
import "@fontsource/noto-sans/400.css"; 
import "@fontsource/noto-sans/400-italic.css";

const tourSteps = [
  {
    selector: '.navigation',
    content: <TourContent heading={"Navigating SpoofCard"} 
      body={"Want to place a call, send a text, or listen to your recordings? You can navigate to different " +
      "parts of SpoofCard by using this navigation bar."} />,
  },
  {
    selector: '#CreateCallInputs',
    content: <TourContent heading={"Setting up a call"} 
      body={"Enter the number you want to call and the number you want to display. Not sure what number to use? " +
      "Click Random Number and we'll generate one for you."} />,
  },
  {
    selector: '#CallFeatures',
    content: <TourContent heading={"Enabling call features"} 
      body={"Change your voice or add a background noise to disguise your location. You can even record the " +
      "call or skip straight to voicemail if you're in a rush."} />,
  },
  // {
  //   selector: '#WifiCall',
  //   content: <TourContent heading={"Selecting a call method"} 
  //     body={"Change your voice or add a background noise to disguise your location. You can even record the " +
  //     "call or skip straight to voicemail if you're in a rush."} />,
  // },
  {
    selector: '.PlaceCallButton',
    content: <TourContent heading={"Placing the call"} 
      body={"When you're ready to place the call, press here. The recipient will see the Caller ID you " +
      "chose and your personal number will be protected."} />,
  },
  {
    selector: '.CreditsPaper',
    content: <TourContent heading={"Adding more credits"} 
      body={"Calls will deduct credits depending on the features enabled and length of the call. " +
      "If you need to add more, you can choose from a variety of packages."} />,
  },
]

if (window.innerWidth < 601) {
    tourSteps[0].selector = '.MobileNavigation';
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <TourProvider steps={tourSteps} disableInteraction inViewThreshold={20} highlightedMaskClassName="highlightedTourMask"
    scrollSmooth showBadge={false} >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </TourProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
