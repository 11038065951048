import React, { useState } from 'react';
import { Button, Stack, TextField } from '@mui/material';
import ErrorAlert from 'components/common/ErrorAlert';
import Cookies from 'js-cookie';
import AxiosRequest from 'api/AxiosRequest';

const CreateAccount = ({verificationData, receipt}) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    const [userPIN, setUserPIN] = useState("");
    const [confirmPIN, setConfirmPIN] = useState("");
    const [receivedFreeCredits, setReceivedFreeCredits] = useState(null);

    const CheckFreeCreditsReceived = () => {
        AxiosRequest('Promotions/free-signup-credits/received', {}, "GET").then((response) => {
			setReceivedFreeCredits(response.data.received_promotion);
            if (response.data.received_promotion === true) {
                window.location.href = "/?welcome-credits=1";
            } else {
                window.location.href = "/?welcome-credits=0";
            }
        })
        .catch((error) => {
            console.log(error);
            window.location.href = "/";
        });
	}

    const HandleCreatAccountSubmit = (e) => {
        e.preventDefault();
        
        let data = {
            "verification_receipt": receipt,
            "phone_number": verificationData.phone_number,
            "email_address": verificationData.email_address,
            "passcode": userPIN,
        }         

        if (userPIN === confirmPIN) {
            AxiosRequest("FreeAccount/", data).then((response) => {
                if (response.data.resources.token.access_token) {
                    Cookies.set("access_token", response.data.resources.token.access_token, {
                        expires: 7,
                    });

                    window.dataLayer.push({
                        'event': 'web_signup',
                        'email': verificationData.email_address,
                        'phone': verificationData.phone_number
                    });

                    CheckFreeCreditsReceived();
                }
                setErrorMessage(null);
            })
            .catch(error => {
                console.log(error);
                if (error.response.status && error.response.status >= 500) {
                    setErrorMessage("There was an error. Please try again.");
                    setErrorCode(error.response.data.error.code);        
                } else if (error.response.data.error) {
                    setErrorMessage(error.response.data.error.message);
                    setErrorCode(error.response.data.error.code);
                } else {
                    setErrorMessage("There was an error. Please try again.");
                }
            })
        } else {
            setErrorMessage("PINs do not match.");
        }
    }

    const handlePinChange = (e, field) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (field === "user") {
            setUserPIN(onlyNums);
        } else if (field === "confirm") {
            setConfirmPIN(onlyNums);
        }
    }

    return (
        <section className={"create-account-step step-section"}>
            <h2>Set your PIN</h2>
            <p>Choose a 4-digit PIN you will use to log in. PIN can contain only numerical digits.</p>
            <form action='' id="loginForm" onSubmit={HandleCreatAccountSubmit}>
                <Stack direction="column" spacing={ 2 }>
                    <TextField label="Enter a 4-digit PIN"  variant="outlined" size="medium" type='password'
                        onChange={e => handlePinChange(e, "user")} value={userPIN}
                        inputProps={{ maxLength: 4 }} className='password-input'
                    />
                    <TextField label="Confirm your 4-digit PIN"  variant="outlined" size="medium" type='password'
                        onChange={e => handlePinChange(e, "confirm")} value={confirmPIN}
                        inputProps={{ maxLength: 4 }} className='password-input'
                    />
                    {errorMessage &&
                    <ErrorAlert message={errorMessage} code={errorCode} />
                    }
                    <Button variant="contained" size="large" type="submit" className='login-submit'>
                        Sign Up
                    </Button>
                </Stack>
            </form>
        </section>
    )
}

export default CreateAccount;